<template>
  <div
    class="register-main register-main--spaced"
  >
    <div class="fill-width">
      <ModalHeader
        v-if="!inSimulation"
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!inSimulation" :step="3" />
      <ModalContent
        :content-sections="inSimulation
          ? ['subtitle', 'selectionButton'] : ['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.brother_in_campus.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="currentSchoolToSet" style="margin-top: 30px">
        <div v-for="index in numberOfBrothers" :key="index">
          <ModalContent
            v-if="!inSimulation"
            :content-sections="['subtitle', 'autoComplete']"
            :subtitle-text="'user_register.brother_in_campus.nationality'"
            :auto-complete-placeholder="'user_register.basic_info.nationality_placeholder'"
            :auto-complete-items="setNationalityArray()"
            :autocomplete-no-info-error="noInfoError && nationality.length === 0"
            :autocomplete-index="index"
            :autocomplete-initial-value="
              defaultStudent.length >= index ? defaultStudent[index - 1].nationalityStudent : ''
            "
            @setAutoComplete="setNationality"
          />
          <ModalContent
            :content-sections="inSimulation ? ['subtitle'] : ['subtitle', 'autoComplete']"
            :subtitle-text="'user_register.campus_selection.which_one'"
            :auto-complete-placeholder="'user_register.register_location.department_placeholder'"
            :auto-complete-items="regionLabels"
            :autocomplete-no-info-error="noInfoError && departments.length === 0"
            :autocomplete-index="index"
            :autocomplete-initial-value="
              defaultStudent.length >= index ? defaultStudent[index - 1].department : ''
            "
            @setAutoComplete="setDepartment"
          />
          <div v-for="i in 2" :key="i">
            <ModalContent
              style="margin: -4px 0"
              :content-sections="['autoComplete']"
              :auto-complete-placeholder="
                i === 1
                  ? 'user_register.register_location.commune_placeholder'
                  : 'user_register.campus_selection.placeholder'
              "
              :auto-complete-items="i === 1 ? municipalityLabels : communeInstitutionListSorted"
              :disabled-autocomplete-value="i === 1 ? false : (communeInstitutionList.length === 0 && !preFill)"
              :autocomplete-no-info-error="noInfoError && communes.length === 0"
              :autocomplete-index="index"
              :autocomplete-reset-value="setResetValue(index)"
              :autocomplete-initial-value="getInstitutionSibling(index, i)"
              @setAutoComplete="
                i === 1 ? setMuncipality($event, index, i) : setSchools($event, index, i)
              "
            />
          </div>
          <section :class="inSimulation ? 'd-flex' : ''">
            <ModalContent
              :content-sections="getModalContent()"
              :subtitle-text="inSimulation
                ? 'user_register.basic_info.brother_question' : 'user_register.campus_selection.idTitle'"
              :text-select-items="setTypeOfDocuments()"
              :text-select-placeholders="'user_register.basic_info.document_type_placeholder'"
              :text-select-index="index - 1"
              :input-section="
                defaultStudent.length > 0
                  ? defaultStudent[index - 1].inputContent.firstName
                  : setInputContent(1)
              "
              :text-select-initial-value="
                defaultStudent.length >= index ? defaultStudent[index - 1].docTypeStudent : ''
              "
              @setTextSelectValue="setTextValue"
              @setValue="setInputValue($event, index)"
            />
            <ModalContent
              v-if="inSimulation"
              style="margin-top: 70px; margin-left: 22px;"
              :content-sections="['registerInput']"
              :input-section="
                defaultStudent.length > 0
                  ? defaultStudent[index - 1].inputContent.lastName
                  : setInputContent(2)
              "
              :text-select-initial-value="
                defaultStudent.length >= index ? defaultStudent[index - 1].docTypeStudent : ''
              "
              @setTextSelectValue="setTextValue"
              @setValue="setInputValue($event, index, true)"
            />
          </section>
          <ModalContent
            :content-sections="['subtitle', 'selectionButton']"
            :subtitle-text="'user_register.brother_in_campus.another_brother'"
            :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
            :selection-button-info="setSelectionButtonInfo(index)"
            @setSelectionButtonValue="setButtonsValue2"
          />
        </div>
      </section>
    </div>
    <section class="d-flex align-center justify-end fill-width">
      <CallToActions
        style="margin-top: 40px; margin-right: 8px"
        :types="['main']"
        :active-value="!inSimulation ? isActive : false"
        :main-button-text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'user_register.continue'"
        small
        :main-button-tiny="mobile"
        :spaced="false"
        :main-active-light="inSimulation"
        @continue="inSimulation ? $emit('backStep') : nextStep()"
      />
      <CallToActions
        v-if="inSimulation"
        style="margin-top: 40px; margin-left: 8px"
        :types="['main']"
        :active-value="isActive"
        :main-button-text="'user_register.brother_in_campus.confirm'"
        small
        :main-button-tiny="mobile"
        width-stretch
        :spaced="false"
        @continue="nextStep()"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'BrotherInCampus',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    preFill: {
      type: Boolean,
      default: false,
    },
    inSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
      currentSchoolToSet: false,
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      noInfoError: false,
      communes: [],
      departments: [],
      schools: [],
      numberOfBrothers: 0,
      recommendedSchools: null,
      schoolNamesList: [],
      municipalityList: [],
      valueList: [],
      brothersInfo: [],
      brothersDocNumber: [],
      noId: [],
      docType: '',
      nationality: '',
      docNumber: '',
      defaultStudent: [],
      defaultDepartment: '',
      defaultCommune: '',
      defaultInstitution: '',
      defaultDocument: '',
      brotherPersonalInfo: [],
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      municipalityLabels: 'options/municipalityLabels',
      regions: 'options/regions',
      regionLabels: 'options/regionLabels',
      communeInstitutionList: 'institutions/communeInstitutionList',
      documentTypes: 'options/documentTypes',
      getSchoolInstitutionList: 'institutions/getSchoolInstitutionList',
      labelsByKey: 'explorer/labelsByKey',
    }),
    nationalities() {
      return this.labelsByKey(LABELS.NATIONALITIES);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    communeInstitutionListSorted() {
      const institutionsSorted = this.communeInstitutionList;
      return institutionsSorted.sort((a, b) => a.localeCompare(b));
    },
    /* Check for region set if its necessary
    isNotSetRegion() {
      if (this.departments.length === 0) {
        return true;
      }
      return false;
    },
    */
  },
  watch: {
    inputValues: {
      handler(newVal) {
        if (this.preFill && !this.inSimulation) {
          newVal.forEach((student, index) => {
            if (
              student
              && Object.keys(student).includes('hasBrothers')
              && student.hasBrothers !== null
            ) {
              if (student.hasBrothers) {
                this.selectionButtonInfo[0].isActive = true;
                this.selectionButtonInfo[1].isActive = false;
                this.numberOfBrothers = student.lenBrothers;
                this.currentSchoolToSet = true;
                this.isActive = true;
              } else {
                this.selectionButtonInfo[0].isActive = false;
                this.selectionButtonInfo[1].isActive = true;
                this.currentSchoolToSet = false;
                this.isActive = true;
              }
            }
            if (
              student
              && Object.keys(student).includes('nationality')
              && student.nationality !== null
            ) {
              this.defaultNationality = this.nationalities.find(
                (country) => country.id === student.nationality,
              ).nationality_name;
              this.defaultNationality = this.defaultNationality.charAt(0).toUpperCase()
                + this.defaultNationality.slice(1).toLowerCase();
              this.nationality = this.defaultNationality;
              this.setTypeOfDocuments();
            }
            if (
              student
              && Object.keys(student).includes('identification_type_id')
              && student.identification_type_id !== null
              && student.nationality !== null
            ) {
              this.defaultDocument = this.documentTypes[student.nationality].find(
                (docu) => docu.id === student.identification_type_id,
              ).identification_type_name;
              this.docType = this.defaultDocument;
              if (this.docType === 'Sin documento') {
                this.noId = true;
              }
              this.brothersInfo.push({
                index,
                docId: student.identification_type_id,
              });
            }
            if (
              student
              && Object.keys(student).includes('identification_number')
              && student.identification_number !== null
            ) {
              this.docNumber = student.identification_number;
              this.brothersDocNumber.push({
                index: index + 1,
                docNumber: this.docNumber,
              });
            }
            this.defaultStudent.push({
              nationalityStudent: this.nationality,
              docTypeStudent: this.docType,
              inputContent: [
                {
                  value: this.docNumber,
                  noInfoError: false,
                  placeholder: 'user_register.basic_info.document_number_placeholder',
                  show: true,
                },
              ],
            });
          });
        }
      },
    },
  },
  async mounted() {
    if (this.preFill && this.inSimulation) {
      const student = this.currentStudent;
      const hasBrothers = student.siblings?.length > 0;
      if (hasBrothers) {
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
        this.numberOfBrothers = student.siblings?.length;
        this.currentSchoolToSet = true;
        this.isActive = true;
        student.siblings.forEach((sibling, index) => {
          this.brotherPersonalInfo.push({
            index,
            firstName: sibling.first_name ? sibling.first_name : null,
            lastName: sibling.first_lastname ? sibling.first_lastname : null,
          });
          if (sibling.institution_code !== null) {
            const filter = [
              {
                fieldname: ELASTIC_SEARCH_FIELDS.INSTITUTION_CODE,
                fieldvalue: sibling.institution_code,
              },
            ];
            this.getSchoolInstitution({ filter }).then(() => {
              const studentSchoolInfo = this.getSchoolInstitutionList[index];
              if (this.communes.length === 0) {
                this.communes.push({ value: studentSchoolInfo.commune });
              } else {
                const communeInArray = this.communes.find((commune) => commune.value === studentSchoolInfo.commune);
                if (!communeInArray) {
                  this.communes.push({ value: studentSchoolInfo.commune });
                }
              }
              this.setSchoolListArray(index + 1);
              const filterList = [
                {
                  fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
                  fieldvalue: studentSchoolInfo.commune,
                },
              ];
              this.getInstitutionsFromCommune({ filterList }).then(() => {
                this.setSchools(studentSchoolInfo.name, index + 1);
              });
              this.schoolNamesList.push(studentSchoolInfo.commune);
              this.defaultStudent.push(
                {
                  commune: studentSchoolInfo.commune,
                  institution: studentSchoolInfo.name,
                  inputContent: {
                    firstName: [
                      {
                        value: sibling.first_name,
                        noInfoError: false,
                        placeholder: 'user_register.basic_info.name_placeholder',
                        show: true,
                      },
                    ],
                    lastName: [
                      {
                        value: sibling.first_lastname,
                        noInfoError: false,
                        placeholder: 'user_register.basic_info.last_name_placeholder',
                        show: true,
                      },
                    ],
                  },
                },
              );
            });
          }
        });
      }
    }
    if (this.preFill && !this.inSimulation) {
      // NO PROP INPUT VALUE
      // eslint-disable-next-line
      for (const index in this.inputValues) {
        const student = this.inputValues[index];
        if (
          student
          && Object.keys(student).includes('hasBrothers')
          && student.hasBrothers !== null
        ) {
          if (student.hasBrothers) {
            this.selectionButtonInfo[0].isActive = true;
            this.selectionButtonInfo[1].isActive = false;
            this.numberOfBrothers = student.lenBrothers;
            this.currentSchoolToSet = true;
            this.isActive = true;
          } else {
            this.selectionButtonInfo[0].isActive = false;
            this.selectionButtonInfo[1].isActive = true;
            this.currentSchoolToSet = false;
            this.isActive = true;
          }
        }
        if (
          student
          && Object.keys(student).includes('nationality')
          && student.nationality !== null
        ) {
          this.defaultNationality = this.nationalities.find(
            (country) => country.id === student.nationality,
          ).nationality_name;
          this.defaultNationality = this.defaultNationality.charAt(0).toUpperCase()
            + this.defaultNationality.slice(1).toLowerCase();
          this.nationality = this.defaultNationality;
          this.setTypeOfDocuments();
        }
        if (
          student
          && Object.keys(student).includes('identification_type_id')
          && student.identification_type_id !== null
          && student.nationality !== null
        ) {
          this.defaultDocument = this.documentTypes[student.nationality].find(
            (docu) => docu.id === student.identification_type_id,
          );
          this.docType = this.defaultDocument?.identification_type_name;
          if (this.docType === 'Sin documento') {
            this.noId = true;
          }
          this.brothersInfo.push({
            index,
            docId: student.identification_type_id,
          });
        }
        if (
          student
          && Object.keys(student).includes('identification_number')
          && student.identification_number !== null
        ) {
          this.docNumber = student.identification_number;
          this.brothersDocNumber.push({
            index,
            docNumber: this.docNumber,
          });
        }
        if (
          student
          && Object.keys(student).includes('institution_name')
          && student.institution_name !== null
        ) {
          this.defaultInstitution = student.institution_name;
        }
        if (student && Object.keys(student).includes('commune') && student.commune !== null) {
          this.defaultCommune = student.commune;
          // eslint-disable-next-line
          this.setMuncipality(this.defaultCommune, Number(index) + 1, 1);
        }
        if (student && Object.keys(student).includes('region') && student.region !== null) {
          this.defaultDepartment = student.region;
          this.setDepartment(this.defaultDepartment, Number(index) + 1);
        }

        this.defaultStudent.push({
          institution: this.defaultInstitution,
          commune: this.defaultCommune,
          department: this.defaultDepartment,
          nationalityStudent: this.nationality,
          docTypeStudent: this.docType,
          inputContent: [
            {
              value: this.docNumber,
              noInfoError: false,
              placeholder: 'user_register.basic_info.document_number_placeholder',
              show: true,
            },
          ],
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setBloodSiblingInfo: 'newStudentRegister/setBloodSiblingInfo',
      getSchoolInstitution: 'institutions/getSchoolInstitution',
      getInstitutionsFromCommune: 'institutions/getInstitutionsFromCommune',
      setSchoolNameList: 'institutions/setSchoolNameList',
    }),
    getInputPlaceholder(index) {
      if (index === 1) {
        return this.inSimulation ? 'user_register.basic_info.name_placeholder' : 'user_register.basic_info.document_number_placeholder';
      }
      if (index === 2) {
        return this.inSimulation ? 'user_register.basic_info.last_name_placeholder' : 'user_register.basic_info.document_number_placeholder';
      }
      return 'user_register.basic_info.document_number_placeholder';
    },
    getModalContent() {
      if (this.noId.length > 0) {
        return ['subtitle', 'textSelect'];
      }
      if (this.inSimulation) {
        return ['subtitle', 'registerInput'];
      }
      return ['subtitle', 'textSelect', 'registerInput'];
    },
    getInstitutionSibling(index, i) {
      if (this.defaultStudent.length > 0 && i === 1) {
        return this.defaultStudent[index - 1].commune;
      }
      if (this.defaultStudent.length > 0 && i === 2) {
        return this.defaultStudent[index - 1].institution;
      }
      return '';
    },
    setNationalityArray() {
      const textArray = this.nationalities
        .map((nationality) => nationality.nationality_name)
        .map((label) => {
          const newLabel = label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
          return newLabel;
        });
      return textArray;
    },
    setResetValue(index) {
      if (this.valueList[index]) {
        return true;
      }
      return false;
    },
    setSelectionButtonInfo(index) {
      const selectionButtonInfo2 = [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ];
      if (index < this.numberOfBrothers) {
        selectionButtonInfo2[0].isActive = true;
      }
      if (index === this.numberOfBrothers) {
        selectionButtonInfo2[1].isActive = true;
      }
      return selectionButtonInfo2;
    },
    setMunicipalitesArray(index) {
      let municipalitiesLabels = [];
      if (this.departments.length > 0) {
        if (this.departments[index - 1]) {
          const regionInfo = this.regions.find(
            (region) => region.label === this.departments[index - 1].value,
          );
          this.selectedMuncipalities = regionInfo.commune_options;
          municipalitiesLabels = this.selectedMuncipalities.map((muni) => muni.name);
        }
      }
      if (this.municipalityList.length < index) {
        this.municipalityList.push(municipalitiesLabels);
      } else {
        this.valueList.push(true);
        this.municipalityList[index - 1] = municipalitiesLabels;
      }
      return this.municipalityList;
    },
    setSchoolListArray(index) {
      this.valueList.push(false);
      if (this.communes.length > 0) {
        const filter = [
          {
            fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
            fieldvalue: this.communes[index - 1].value,
          },
        ];
        this.getInstitutionsFromCommune({ filter }).then((response) => {
          this.recommendedSchools = response.results;
          return response.results;
        });
      }
      return null;
    },
    setTypeOfDocuments() {
      let nationalityId = 1;
      if (this.nationality) {
        nationalityId = this.nationalities.find(
          (nationality) => nationality.nationality_name === String(this.nationality).toUpperCase(),
        ).id;
      }
      if (Object.keys(this.documentTypes).length > 0) {
        const documents = this.documentTypes[nationalityId];
        const documentArray = documents.map((docs) => {
          const newLabel = docs.identification_type_name;
          return newLabel;
        });
        this.typeOfDocuments = documentArray;
        return documentArray;
      }
      return [];
    },
    setTextValue(index, value) {
      if (value === 'Sin documento') {
        this.noId.push({
          noId: true,
          index,
        });
        return this.noId;
      }
      let nationalityId = 1;
      if (this.nationality) {
        nationalityId = this.nationalities.find(
          (nationality) => nationality.nationality_name === String(this.nationality).toUpperCase(),
        ).id;
      }
      const docId = this.documentTypes[nationalityId].find(
        (type) => type.identification_type_name === value,
      ).id;
      if (
        this.brothersInfo.length > 0
        && this.brothersInfo[index]
        && this.brothersInfo[index].index === index
      ) {
        this.brothersInfo[index].value = value;
      } else {
        this.brothersInfo.push({
          index,
          docId,
        });
      }
      return null;
    },
    setInputContent(index) {
      const inputContent = [
        {
          value: null,
          noInfoError: false,
          placeholder: this.getInputPlaceholder(index),
          show: true,
        },
      ];
      return inputContent;
    },
    /*
      Set input value to data --> Index for control of input brother value, index = 1 - its the same as first brother
      last arg boolean for las name identification input
    */
    setInputValue(mainValue, index, isLastName) {
      const brothersIndex = index - 1;
      if (this.inSimulation) {
        if (this.brotherPersonalInfo.length === 0) {
          if (!isLastName) {
            this.brotherPersonalInfo.push({
              index: brothersIndex,
              firstName: mainValue,
            });
          } else {
            this.brotherPersonalInfo.push({
              index: brothersIndex,
              lastName: mainValue,
            });
          }
        } else if (
          this.brotherPersonalInfo.length > 0
          && this.brotherPersonalInfo[brothersIndex]
          && this.brotherPersonalInfo[brothersIndex].index === brothersIndex
        ) {
          if (!isLastName) {
            this.brotherPersonalInfo[brothersIndex].firstName = mainValue;
          } else {
            this.brotherPersonalInfo[brothersIndex].lastName = mainValue;
          }
        }
      }
      if (
        this.brothersDocNumber.length > 0
        && this.brothersDocNumber[brothersIndex]
        && this.brothersDocNumber[brothersIndex].index === brothersIndex
        && !this.inSimulation
      ) {
        this.brothersDocNumber[brothersIndex].docNumber = mainValue;
      } else {
        this.brothersDocNumber.push({
          index: brothersIndex,
          docNumber: mainValue,
        });
      }
      return null;
    },
    setDepartment(value, index) {
      if (this.departments.length < index) {
        if (value) {
          this.departments.push({ value, index });
        }
      } else {
        this.departments[index - 1] = { value, index };
      }
      this.setMunicipalitesArray(index);
      return this.departments;
    },
    async setMuncipality(value, index, i) {
      if (value && i === 1) {
        if (this.communes.length < index) {
          this.communes.push({ value });
          await this.setSchoolListArray(index);
        } else {
          this.communes[index - 1] = { value };
          await this.setSchoolListArray(index, true);
        }
      }
      return this.communes;
    },
    setSchools(value, index) {
      const institutionCode = this.recommendedSchools.find(
        (school) => school.institution_name === value,
      ).institution_code;
      if (
        this.schools.length > 0
        && this.schools[index - 1]
        && this.schools[index - 1].index === index - 1
      ) {
        this.schools[index - 1].schoolId = institutionCode;
      } else {
        this.schools.push({
          index: index - 1,
          schoolId: institutionCode,
        });
      }
      return null;
    },
    setNationality(value) {
      this.nationality = value;
      return this.nationality;
    },
    nextStep() {
      if (!this.isActive) {
        return null;
      }
      const brotherMainInfo = [];
      this.schools.forEach((school, index) => {
        const nationalityUpper = String(this.nationality).toUpperCase();
        const nationalityId = this.nationalities.find(
          (country) => country.nationality_name === nationalityUpper,
        );
        const idType = this.brothersInfo.find(
          (docuInfo) => parseInt(docuInfo.index, 10) === school.index,
        );

        const idNumber = this.brothersDocNumber.find(
          (docuNum) => docuNum.index === school.index,
        );
        const brotherPersonalInfo = this.brotherPersonalInfo.find(
          (personalInfo) => personalInfo.index === school.index,
        );
        brotherMainInfo.push({
          uuid: this.currentStudent.uuid,
          nationality_id: this.inSimulation ? null : nationalityId.id,
          identification_type_id: idType && !this.inSimulation ? idType.docId : null,
          institution_code: school.schoolId,
          identification_number: this.inSimulation ? null : idNumber?.docNumber,
        });
        if (this.inSimulation) {
          Object.assign(brotherMainInfo[index], { first_name: brotherPersonalInfo.firstName });
          Object.assign(brotherMainInfo[index], { first_lastname: brotherPersonalInfo.lastName });
        }
      });
      this.setBloodSiblingInfo({ brothersInfo: brotherMainInfo, updateStudents: this.inSimulation });
      this.$emit('nextStep');
      return null;
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.numberOfBrothers += 1;
        this.currentSchoolToSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.numberOfBrothers -= 1;
        this.currentSchoolToSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      this.isActive = true;
      return i;
    },
    setButtonsValue2(i) {
      if (i === 0) {
        this.numberOfBrothers += 1;
      } else {
        this.numberOfBrothers -= 1;
      }
      this.isActive = true;
      return i;
    },
  },
};
</script>
