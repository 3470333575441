<template>
  <!-- Este componente es pensado para poder ser eliminado en un futuro, ya que
  tiene mucho código en común con ProfileEdit. Pero en organización, diseño y layout es bien diferente.
  Así que a futuro al tener el diseño final de edición headMaster y ambassador (Profile.vue)
  se puede unir todo en un solo componente con el de legalGuardian (ProfileEdit.vue). -->
  <div class="profile dashboard-section-content">
    <div
      class="pb-2"
      style="
        background-color: #ffffff;
        border-radius: 4px 4px 4px 4px;
        padding-top: 15px;
      "
    >
      <div class="profile__registration__steps">
        <div class="profile__registration__steps__div">
          <img
            v-if="!isGuest"
            style="margin-right: 5px"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            alt="check"
          />
          <img
            v-else
            style="margin-right: 5px"
            src="@/assets/dashboard/icons/disabled-check.svg"
            alt="disabled-check"
          />
          <h3
            class="profile__registration__steps__div__title"
            :class="{
              'profile__registration__steps__div__title--completed': !isGuest,
            }"
          >
            {{ $t("dashboard.profile.identification") }}
          </h3>
        </div>
        <div
          v-if="!isGuest"
          class="d-flex align-center"
          @click="showLgIdentificationModal"
          @keydown="showLgIdentificationModal"
        >
          <div class="d-flex align-center">
            <p
              v-t="'dashboard.profile.edit'"
              class="profile__registration__steps__complete"
            />
            <v-btn
              class="profile__students__div__delete"
              type="button"
              icon
              color="#2b5bff"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="profile__registration__steps">
        <div class="profile__registration__steps__div">
          <img
            v-if="!isGuest"
            style="margin-right: 5px"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            alt="check"
          />
          <img
            v-else
            style="margin-right: 5px"
            src="@/assets/dashboard/icons/disabled-check.svg"
            alt="disabled-check"
          />
          <h3
            class="profile__registration__steps__div__title"
            :class="{
              'profile__registration__steps__div__title--completed': !isGuest,
            }"
          >
            {{ $t("dashboard.profile.Location") }}
          </h3>
        </div>
        <div
          v-if="!isGuest"
          class="d-flex align-center"
          @click="showLocationModal"
          @keydown="showLocationModal"
        >
          <p
            v-t="'dashboard.profile.edit'"
            class="profile__registration__steps__complete"
          />
          <v-btn
            class="profile__students__div__delete"
            type="button"
            icon
            color="#2b5bff"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-if="!isHeadMaster"
      class="profile__students"
      :class="{ 'profile__students--guest': isGuest }"
    >
      <div
        class="profile__students__div flex-row justify-space-between"
        :class="{ 'profile__students__div--underline': !isGuest }"
      >
        <div class="profile__students__div__header">
          <img
            v-if="!isGuest && students.length > 0"
            style="margin-right: 4px"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            alt="check"
          />
          <img
            v-else
            style="margin-right: 4px"
            src="@/assets/dashboard/icons/disabled-check.svg"
            alt="disabled-check"
          />
          <h3
            class="profile__students__div__header__title"
            :class="{
              'profile__students__div__header__title--completed':
                students.length > 0,
            }"
          >
            {{ $t("dashboard.profile.students") }}
          </h3>
        </div>
        <img
          v-if="!isGuest"
          class="profile__students__div__icon"
          src="@/assets/iconsNewHaven/dashboard/add.svg"
          alt="add"
          @click="addStudent"
          @keydown="addStudent"
        />
      </div>
      <div
        v-for="(student, index) in students"
        :key="index"
        class="profile__students__div profile__students__div--list"
      >
        <div class="d-flex fill-width -justify-space-between">
          <div class="profile__students__div__wrap">
            <div class="profile__students__div__header">
              <img
                style="margin-right: 5px"
                src="@/assets/iconsNewHaven/dashboard/check.svg"
                alt="check"
              />
              <h3
                class="profile__students__div__header__student"
                :class="{
                  'profile__students__div__header__student--completed': true,
                }"
              >
                {{ setStudentName(student) }}
              </h3>
            </div>
            <div
              v-if="false"
              class="profile__students__div__selected"
              style="margin-right: 35px"
            />
          </div>
          <div
            style="margin-right: 8px"
            class="d-flex align-center"
            @click="editStudent(student)"
            @keydown="editStudent(student)"
          >
            <p
              v-t="'dashboard.profile.edit'"
              class="profile__students__div__delete-text"
            />
            <v-btn
              class="profile__students__div__delete"
              type="button"
              icon
              color="#2b5bff"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="profile__students__div__container">
          <v-btn
            type="button"
            icon
            class="profile__students__div__delete"
            color="red"
            @click="removeStudent(student)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
          <p
            v-t="'dashboard.profile.delete'"
            class="profile__students__div__delete-text"
          />
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showStudentEditModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <EditStudentSelection
        @backStep="showStudentEditModal = false"
        @nextStep="setEditStudent"
      />
    </v-dialog>
    <v-dialog
      v-model="showIdentificationModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <Identification
        v-if="stepAux === 1"
        no-stepper
        in-detailed-register
        :in-student-flow="inStudentFlow"
        :is-new-student="newStudent"
        :pre-fill-data="inStudentFlow ? studentToEdit : legalGuardian"
        :pre-fill="newStudent ? false : true"
        @backStep="backStep('identification')"
        @nextStep="setIdentificationStep()"
      />
      <Nationality
        v-if="stepAux === 2"
        :in-student-flow="inStudentFlow"
        :pre-fill-data="inStudentFlow ? studentToEdit : legalGuardian"
        :pre-fill="newStudent ? false : true"
        @backStep="inStudentFlow ? (stepAux = 6) : (stepAux = 1)"
        @nextStep="inStudentFlow ? nextStep() : (stepAux = 3)"
      />
      <Contact
        v-if="stepAux === 3"
        no-stepper
        :in-student-flow="inStudentFlow"
        :pre-fill-data="contactPreferences"
        pre-fill
        @backStep="stepAux = 2"
        @nextStep="setNextStep()"
      />
      <RelationshipModal
        v-if="stepAux === 4"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 1"
        @nextStep="stepAux = 5"
      />

      <RegisterGender
        v-if="stepAux === 5"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 4"
        @nextStep="stepAux = 6"
      />
      <DateOfBirth
        v-if="stepAux === 6"
        :in-student-flow="inStudentFlow"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 5"
        @nextStep="nextStep()"
      />
      <PriorityQuestion
        v-if="stepAux === 7"
        @backStep="backStep('additional')"
        @nextStep="stepAux = 8"
        @toFinale="showIdentificationModal = false"
      />
      <BrotherInCampus
        v-if="stepAux === 8"
        :pre-fill="newStudent ? false : true"
        :input-values="studentBrothers"
        @backStep="stepAux = 7"
        @nextStep="nextStep()"
      />
      <CampusSelection
        v-if="stepAux === 9"
        @backStep="stepAux = 8"
        @nextStep="stepAux = 10"
      />
      <SisbenPriority
        v-if="stepAux === 10"
        :pre-fill="newStudent ? false : true"
        :input-values="studentSisben"
        @toIcbfInfo="stepAux = 12"
        @backStep="stepAux = 8"
        @nextStep="stepAux = 11"
      />
      <PreSchoolPriority
        v-if="stepAux === 11"
        :pre-fill="newStudent ? false : true"
        :input-values="studentIcbf"
        @backStep="stepAux = 10"
        @nextStep="stepAux = 13"
      />
      <IcbfInfoModal
        v-if="stepAux === 12"
        @backStep="stepAux = 10"
        @nextStep="stepAux = 13"
      />
      <ArmedConflict
        v-if="stepAux === 13"
        :pre-fill="newStudent ? false : true"
        :input-values="studentConflict"
        @backStep="stepAux = 11"
        @nextStep="stepAux = 14"
      />
      <Disability
        v-if="stepAux === 14"
        :pre-fill="newStudent ? false : true"
        :input-values="studentDisability"
        @nextStep="setNextStep()"
        @backStep="stepAux = 13"
      />
    </v-dialog>
    <v-dialog
      v-model="showEditLocation"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
      style="height: 100vh !important"
      @click:outside="showEditLocation = false"
    >
      <Location
        v-if="locationStep === 0"
        is-in-map
        @backStep="backStep('location')"
        @setCommune="setCommune"
        @setUserLocation="setUserLocation"
      />
      <div
        v-if="locationStep === 1"
        style="height: 100%"
        class="d-flex flex-column justify-space-between"
      >
        <div style="height: 100%">
          <Actions
            style="height: 12px; margin-bottom: 12px"
            @back="locationStep = 0"
          />
          <RegisterLogo inline small />
          <SmallTitle
            style="margin-top: 0px !important"
            center-left
            small-top-margin
            :text="'user_register.register_location.title'"
          />
          <TitleInstructions
            :text="'shared.location.title'"
            style="margin-bottom: 12px"
          />
          <SearchLocation
            v-if="locationStep === 1"
            is-in-map
            :commune="selectedCommune"
            :user-location="userLocation"
            :in-student-flow="inStudentFlow"
            @setUserLocation="setUserLocation"
          />
        </div>
        <div style="padding: 20px 0 0 0">
          <MainButton
            small
            :active-value="true"
            :text="'user_register.continue'"
            @continue="setLocation()"
          />
        </div>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showStudentGradeModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <SelectGrades
        :in-student-flow="inStudentFlow"
        :student-to-set="studentToEdit"
        @sendGrade="setGrades()"
        @backStep="backStep('grade')"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import ArmedConflict from '@/components/organisms/register/ArmedConflict.vue';
import BrotherInCampus from '@/components/organisms/register/BrotherInCampus.vue';
import Contact from '@/components/organisms/register/Contact.vue';
import DateOfBirth from '@/components/organisms/register/DateOfBirth.vue';
import Disability from '@/components/organisms/register/Disability.vue';
import EditStudentSelection from '@/components/organisms/register/EditStudentSelection.vue';
import IcbfInfoModal from '@/components/organisms/register/IcbfInfoModal.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import Location from '@/components/organisms/register/Location.vue';
import Nationality from '@/components/organisms/register/Nationality.vue';
import PreSchoolPriority from '@/components/organisms/register/PreSchoolPriority.vue';
import PriorityQuestion from '@/components/organisms/register/PriorityQuestion.vue';
import RegisterGender from '@/components/organisms/register/RegisterGender.vue';
import RelationshipModal from '@/components/organisms/register/RelationshipModal.vue';
import SisbenPriority from '@/components/organisms/register/SisbenPriority.vue';
import SearchLocation from '@/components/shared/SearchLocation.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import CONFIG from '@/config';
import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';
import trackMixPanel from '@/utils/mixpanel';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'ProfileSection',
  components: {
    Identification,
    Location,
    Actions,
    RegisterLogo,
    SmallTitle,
    TitleInstructions,
    MainButton,
    SearchLocation,
    EditStudentSelection,
    SelectGrades,
    Nationality,
    Contact,
    RegisterGender,
    RelationshipModal,
    DateOfBirth,
    PriorityQuestion,
    BrotherInCampus,
    SisbenPriority,
    IcbfInfoModal,
    PreSchoolPriority,
    ArmedConflict,
    Disability,
  },
  metaInfo: {
    meta: [],
  },
  props: {
    setStudentToEdit: {
      type: Boolean,
      default: false,
    },
    uuidToStudent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepAux: 1,
      showStudentEditModal: false,
      showIdentificationModal: false,
      showEditLocation: false,
      locationStep: null,
      userLocation: null,
      selectedCommune: null,
      selectedLocation: null,
      inStudentFlow: false,
      newStudent: false,
      studentToEdit: null,
      showStudentGradeModal: null,
      studentBrothers: [],
      studentSisben: null,
      studentIcbf: null,
      studentConflict: null,
      finalLat: null,
      finalLng: null,
      locationT: '',
      initialLat: null,
      initialLng: null,
    };
  },
  computed: {
    ...mapGetters({
      students: 'authentication/students',
      isHeadMaster: 'roles/isHeadMaster',
      isGuest: 'authentication/isGuest',
      legalGuardian: 'authentication/legalGuardian',
      currentStudent: 'authentication/currentStudent',
      municipalities: 'options/municipalities',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      contactPreferences: 'userRegister/contactPreferences',
      getSchoolInstitutionList: 'institutions/getSchoolInstitutionList',
      regions: 'options/regions',
      profileStep: 'userRegister/profileStep',
      modalInfoFlow: 'userRegister/modalInfoFlow',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      locationRegister: 'userRegister/location',
      locationType: 'userRegister/locationType',
      place: 'userRegister/place',
      pinMoved: 'userRegister/pinMoved',
      initialCoordinates: 'userRegister/initialCoordinates',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    systemInfoModal() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => modal.modal_code === '3M.i');
        return infoModal;
      }
      return null;
    },
  },
  watch: {
    showIdentificationModal: {
      handler() {
        if (!this.showIdentificationModal) {
          this.stepAux = 1;
        }
      },
      immediate: true,
    },
    uuidToStudent: {
      handler() {
        if (this.uuidToStudent && this.uuidToStudent.uuid) {
          this.editStudent(this.uuidToStudent);
        }
      },
      immediate: true,
    },

  },
  mounted() {
    this.checkLabels({ keysLabels: [LABELS.GENDERS, LABELS.NATIONALITIES] });
    if (this.setStudentToEdit) {
      this.editStudent(this.uuidToStudent);
    }
    if (this.profileStep && this.profileStep.for === 'student') {
      if (this.profileStep.step === 0) {
        this.addStudent();
      }
      if (this.profileStep.step === 1 && this.students && this.students.length > 0) {
        this.editStudent(this.students[0]);
      }
    }
    if (this.profileStep && this.profileStep.for === 'lg' && this.profileStep.step === 0) {
      if (!this.legalGuardianAddress) {
        this.showEditLocation = true;
        this.locationStep = this.profileStep.step;
      }
    }
    /* If need validation of lg data
      if (!this.lgCompletedData) {
        this.showIdentifactionModal();
      }
    */
    this.setProfileStep({ step: null });
  },
  methods: {
    ...mapActions({
      getStudents: 'authentication/getStudents',
      setCurrentStudent: 'authentication/setCurrentStudent',
      deleteStudent: 'authentication/deleteStudent',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      retrieveCampuses: 'institutions/retrieveCampuses',
      raiseError: 'utils/error',
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      success: 'utils/success',
      setStudentLocation: 'newStudentRegister/setStudentLocation',
      getSchoolInstitution: 'institutions/getSchoolInstitution',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
      clearSchoolInstitution: 'institutions/clearSchoolInstitution',
      setProfileStep: 'userRegister/setProfileStep',
      sendGeocodingHistory: 'geoTools/sendGeocodingHistory',
      checkLabels: 'explorer/checkLabels',
    }),
    setEditStudent(index) {
      if (index === 0) {
        this.showStudentEditModal = false;
        this.showIdentificationModal = true;
        this.stepAux = 1;
      }
      if (index === 1) {
        this.showStudentEditModal = false;
        this.showStudentGradeModal = true;
      }
      if (index === 2) {
        this.showStudentEditModal = false;
        this.locationStep = 0;
        this.showEditLocation = true;
      }
      if (index === 3) {
        this.showStudentEditModal = false;
        this.showIdentificationModal = true;
        this.stepAux = 7;
      }
      return null;
    },
    setNextStep() {
      this.showIdentificationModal = false;
      if (!this.inStudentFlow) {
        this.success(this.$t('user_register.identification.success'));
        return null;
      }
      if (!this.studentToEdit) {
        this.showEditLocation = true;
        this.locationStep = 0;
      }
      this.getStudents({ updateStudent: false });
      this.success(this.$t('user_register.identification.success'));
      return null;
    },
    setStudentName(student) {
      if (student.first_name && student.first_lastname) {
        return `${student.first_name} ${student.first_lastname}`;
      }
      if (student.first_name) {
        return student.first_name;
      }
      return '';
    },
    nextStep() {
      if (this.stepAux === 2 && this.inStudentFlow) {
        this.showIdentificationModal = false;
        this.success(this.$t('user_register.identification.success'));
        this.stepAux = 1;
        return this.stepAux;
      }
      if (this.stepAux === 3) {
        this.stepAux = 1;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      if (this.stepAux === 8) {
        this.stepAux = 10;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      if (this.stepAux === 6) {
        this.stepAux = 2;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      this.stepAux += 1;
      return this.stepAux;
    },
    setIdentificationStep() {
      if (this.inStudentFlow) {
        this.stepAux = 4;
      } else {
        this.stepAux = 2;
      }
      this.getStudents({ updateStudent: false });
      return this.stepAux;
    },
    setCommune(commune) {
      const communeDetails = this.municipalities.find(
        (detailedCommune) => detailedCommune.name === commune,
      );
      this.selectedLocation = {
        coordinates: {
          lat: communeDetails.default_coordinate.lat,
          lng: communeDetails.default_coordinate.lon,
        },
        municipalityName: commune,
        addressDetails: commune,
      };
      this.userLocation = null;
      this.selectedCommune = commune;
      this.locationStep = 1;
    },
    setUserLocation(location) {
      this.selectedCommune = null;
      this.selectedLocation = null;
      this.userLocation = location;
      this.locationStep = 1;
    },
    backStep(modal) {
      if (modal === 'identification') {
        this.showIdentificationModal = false;
        this.getStudents({ updateStudent: false });
      }
      if (modal === 'location') {
        this.showEditLocation = false;
      }
      if (modal === 'grade') {
        this.showStudentGradeModal = false;
      }
      if (modal === 'additional') {
        this.showIdentificationModal = false;
        this.showStudentEditModal = false;
        this.studentToEdit = null;
      }
      if (this.studentToEdit) {
        this.showStudentEditModal = true;
      }
    },
    clearCurrentStudent() {
      this.studentToEdit = null;
      this.setCurrentStudent({ student: {} });
    },
    setLocation() {
      let location = {};
      let addressDetails;
      if (this.locationRegister?.final_address?.lat !== '') {
        this.finalLat = this.locationRegister?.final_address.lat;
        this.finalLng = this.locationRegister?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }
      if (this.locationRegister?.final_address?.formatted_address !== null) {
        addressDetails = this.locationRegister.final_address.formatted_address;
      } else if (this.locationRegister?.address_searched) {
        addressDetails = this.locationRegister.address_searched;
      } else {
        addressDetails = '';
      }
      location = {
        uuid: this.currentStudent.uuid,
        address_details: addressDetails,
        address_name: 1,
        address_lat: this.finalLat,
        address_lon: this.finalLng,
        location: this.place.display1,
      };
      if (this.inStudentFlow) {
        if (!this.studentToEdit) {
          this.studentToEdit = this.students[this.students.length - 1];
        }
        this.geocodingHistory();
        this.setStudentLocation({ location, updateStudent: true }).then(() => {
          this.showEditLocation = false;
          if (this.newStudent) {
            this.success(this.$t('user_register.register_location.final_success'));
          } else {
            this.success(this.$t('user_register.register_location.success'));
          }
        });
        return null;
      }
      if (!this.inStudentFlow) {
        location = {
          address_details: addressDetails,
          address_name: 1,
          address_number: this.locationRegister?.final_address?.street_number !== null
            ? this.locationRegister?.final_address?.street_number
            : null,
          address_street: this.locationRegister?.final_address?.street_name !== null
            ? this.locationRegister?.final_address?.street_name : null,
          address_lat: this.finalLat,
          address_lon: this.finalLng,
          location: this.place.display1,
        };
        this.geocodingHistory();
        this.setFastRegisterLocation({ location }).then(() => {
          this.success(this.$t('user_register.register_location.success'));
        });
      }
      this.locationStep = -1;
      this.showEditLocation = false;
      return this.showEditLocation;
    },
    removeStudent(data) {
      trackMixPanel('remove_student_dashboard');
      this.deleteStudent({
        uuid: data.uuid,
      }).then((student) => {
        if (student) {
          this.changeCurrentStudent(student.uuid);
        }
        if (this.systemInfoModal) {
          const infoModal = this.systemInfoModal;
          infoModal.action_completed = false;
          this.setModalFlowInfo({ modalInfo: infoModal, modalUuid: this.systemInfoModal.uuid });
        }
        this.success(this.$t('dashboard.profile.remove_student_success'));
      });
    },
    changeCurrentStudent(studentUUID) {
      const student = this.students.find((st) => st.uuid === studentUUID);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null }))
        .catch((error) => this.raiseError(error));
    },
    addStudent() {
      this.inStudentFlow = true;
      this.newStudent = true;
      this.clearCurrentStudent();
      trackMixPanel('click_add_student_dashboard');
      this.showIdentificationModal = true;
    },
    async editStudent(student) {
      this.getStudents({ updateStudent: false });
      this.newStudent = false;
      this.changeCurrentStudent(student.uuid);
      trackMixPanel('click_edit_student_dashboard');
      this.inStudentFlow = true;

      if (CONFIG.tenant !== 'chile') { // Processing needed for Colombia-specific modals
      // FIXME: Evaluate splitting this into multiple methods that are called from here
      // Why are we not using the currentStudent instead of the studentToEdit? We're already setting it
        this.studentBrothers = [];

        const hasBrothers = student.siblings?.length > 0;
        this.clearSchoolInstitution();
        if (student.siblings?.length > 0) {
          const lenBrothers = student.siblings.length;
          // eslint-disable-next-line
        for (const index in student.siblings) {
            const sibling = student.siblings[index];
            // let i = index;
            this.studentBrothers.push({
              lenBrothers,
              hasBrothers,
              nationality: sibling.nationality_id !== null ? sibling.nationality_id : null,
              identification_type_id:
              sibling.identification_type_id !== null ? sibling.identification_type_id : null,
              identification_number:
              sibling.identification_number !== null ? sibling.identification_number : null,
              institution_code: sibling.institution_code !== null ? sibling.institution_code : null,
            });
            if (sibling.institution_code !== null) {
              const filter = [
                {
                  fieldname: ELASTIC_SEARCH_FIELDS.INSTITUTION_CODE,
                  fieldvalue: sibling.institution_code,
                },
              ];
              // eslint-disable-next-line
            await this.getSchoolInstitution({ filter }).then(() => {
                this.studentBrothers[index].institution_name = this.getSchoolInstitutionList[index].name;
                this.studentBrothers[index].commune = this.getSchoolInstitutionList[index].commune;
                this.regions.forEach((region) => {
                  region.commune_options.forEach((commune) => {
                    if (commune.name === this.studentBrothers[index].commune) {
                      this.studentBrothers[index].region = region.name;
                      return this.studentBrothers[index].region;
                    }
                    return this.studentBrothers[index].region;
                  });
                });
              });
            }
          }
        } else {
          this.studentBrothers.push({
            hasBrothers,
          });
        }
        if (student.sisben_applicant?.length > 0) {
          this.studentSisben = {
            sisben:
            student.sisben_applicant[0].sisben_category !== null
              ? student.sisben_applicant[0].sisben_category
              : null,
          };
        }
        this.studentIcbf = {
          icbf: student.icbf !== null ? student.icbf : null,
        };
        this.studentConflict = {
          has_conflict:
          student.has_armed_conflict_priority !== null ? student.has_armed_conflict_priority : null,
          conflict_category:
          student.armed_conflict_type?.category_name !== null
          || student.armed_conflict_type?.category_name !== undefined
            ? student.armed_conflict_type?.category_name : null,
        };

        this.studentDisability = {
          has_disability:
          student.has_disability_priority !== null ? student.has_disability_priority : null,
          disability_category:
          student.disability?.disability_name !== null || student.disability?.disability_name !== undefined
            ? student.disability?.disability_name : null,
        };
      }

      this.studentToEdit = student;
      this.showStudentEditModal = true;
    },
    showLgIdentificationModal() {
      this.newStudent = false;
      this.inStudentFlow = false;
      trackMixPanel('click_edit_identification_dashboard/profile');
      this.studentToEdit = null;
      this.showIdentificationModal = true;
    },
    showLocationModal() {
      trackMixPanel('click_edit_location_dashboard/profile');
      this.studentToEdit = null;
      this.inStudentFlow = false;
      this.showEditLocation = true;
      this.locationStep = 0;
    },
    setGrades() {
      this.showStudentGradeModal = false;
      this.success(this.$t('user_register.register_grade.success'));
    },
    geocodingHistory() {
      if (!this.pinMoved && this.locationRegister?.final_address?.formatted_address !== null) {
        this.finalLat = this.place.lat;
        this.finalLng = this.place.lng;
      } else if (this.locationRegister?.final_address?.lat !== '') {
        this.finalLat = this.locationRegister?.final_address.lat;
        this.finalLng = this.locationRegister?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }

      if (this.locationType === 1) {
        this.locationT = 'use_my_location';
      } else if (this.locationType === 2) {
        this.locationT = 'write_address';
      } else if (this.locationType === 3) {
        this.locationT = 'locate_in_map';
      }

      if (this.locationRegister?.final_address?.formatted_address !== undefined) {
        this.initialLat = this.locationRegister?.final_address?.lat;
        this.initialLng = this.locationRegister?.final_address?.lng;
      } else {
        this.initialLat = this.selectedLocation?.coordinates?.lat;
        this.initialLng = this.selectedLocation?.coordinates?.lng;
      }
      const geoInfo = {
        user: this.legalGuardianUUID || null,
        geotools_code_national: this.locationRegister?.code_national,
        final_lat: this.finalLat,
        final_lng: this.finalLng,
        used_text_first: this.locationType === 2,
        method: this.locationT,
        geotools_lat: this.initialLat,
        geotools_lng: this.initialLng,
        geotools_geocodification_type: this.locationRegister?.final_address?.geocodification_type !== '' ? this.locationRegister?.final_address?.geocodification_type : null,
        geotools_address: this.locationRegister?.final_address?.formatted_address !== '' ? this.locationRegister?.final_address?.formatted_address : '',
        user_text_input: this.locationRegister?.address_searched ?? null,
      };
      this.sendGeocodingHistory({ geoInfo });
    },
  },
};
</script>
